import type { OrderProduct } from "../OrderProduct.js";
import type { ProductForCustomer } from "../Product.js";
import type { ProductAmountPerCategory } from "../ProductAmountPerCategory.js";
import { maxProductQuantityInCart } from "../maxProductQuantityInCart.js";

export function getFreeSlotsForProduct(
	product: ProductForCustomer,
	maxProductsPerCategory: ProductAmountPerCategory,
	productsInCart: OrderProduct[],
): number {
	let maximum = maxProductQuantityInCart;
	for (const categoryId of product.categoryIds) {
		const maxAmount = maxProductsPerCategory[categoryId] ?? maxProductQuantityInCart;
		const productsInCategory = productsInCart.filter((orderProduct) =>
			orderProduct.product.categoryIds.includes(categoryId),
		);
		const productsInCategoryCount = productsInCategory.reduce((acc, { quantity }) => acc + quantity, 0);
		const freeSlots = maxAmount - productsInCategoryCount;
		maximum = Math.min(maximum, freeSlots);
	}
	return maximum;
}
