<script lang="ts">
	import type { ProductForCustomer } from "../../../../core/schema/Product.js";
	import type { ProductSize } from "../../../../core/schema/ProductSize.js";
	import { productSizeKeyMap } from "../../../../core/schema/productSizeKeyMap.js";
	import { ProductSizeLabelType } from "../../../../core/schema/ProductSizeLabelType.js";
	import { productSizeLabelTypeShorthandNameMap } from "../../../../core/schema/names/productSizeLabelTypeShorthandNameMap.js";
	import { formatNumber } from "../../../../core/utils/formatNumber.js";
	import { getGlobalSettings } from "../../getGlobalSettings.js";

	export let selectedSize: ProductSize;
	export let product: ProductForCustomer;
	export let isSizeAddedToCart = false;

	const { TENANT_LOCALE } = getGlobalSettings();

	const heightClasses = ["h-[1.5rem]", "h-[1.625rem]", "h-[1.75rem]", "h-[2rem]"];

	$: customSizeLabel = product.sizeLabel && product.sizeLabel.type !== ProductSizeLabelType.Serving;

	// eslint-disable-next-line no-warning-comments
	// FIXME remove this after upgrade to Svelte 5.
	/* eslint-disable @typescript-eslint/explicit-function-return-type */
</script>

<div class="flex flex-row flex-wrap items-end gap-x-2 gap-y-1">
	{#each product.availableSizes as size, i}
		{#if product.priceForCustomer[productSizeKeyMap[size]]}
			{@const id = `${product.id}-${size}`}
			{@const heightClass = heightClasses[i]}
			<span class="{heightClass} relative flex shrink-0" class:aspect-square={!customSizeLabel}>
				<input
					class="custom-radio {isSizeAddedToCart
						? '[&:checked+label]:!bg-tertiary [&:checked+label]:!border-tertiary [&:checked+label]:!pointer-events-none'
						: ''}"
					type="radio"
					value={size}
					{id}
					name="size"
					bind:group={selectedSize}
				/>
				<label for={id} class={heightClass} class:px-2={customSizeLabel} class:aspect-square={!customSizeLabel}>
					{#if product.sizeLabel && product.sizeLabel.type !== ProductSizeLabelType.Serving}
						<div class="flex gap-0.5">
							<span>{formatNumber(product.sizeLabel.values[productSizeKeyMap[size]], TENANT_LOCALE, false)}</span>
							<span class="lowercase">{productSizeLabelTypeShorthandNameMap[product.sizeLabel.type]}</span>
						</div>
					{:else}
						{size}
					{/if}
				</label>
			</span>
		{/if}
	{/each}
</div>
