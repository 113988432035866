import type { ProductForCustomer } from "../Product.js";
import type { ProductSize } from "../ProductSize.js";
import { defaultSize } from "../defaultSize.js";

export function getDefaultSizeForProductForCustomer(product: ProductForCustomer): ProductSize {
	const size = product.availableSizes.includes(defaultSize) ? defaultSize : product.availableSizes[0];
	if (size === undefined) {
		throw new Error(`Product has no available size.`);
	}
	return size;
}
