<script lang="ts">
	import Button from "../Button.svelte";
	import { createEventDispatcher } from "svelte";

	export let id: string;
	export let min = -Infinity;
	export let max = Infinity;
	export let value: number | string = "";
	export let valueAsNumber = Number(value);
	export let required = false;
	export let inputClass = "";
	export let customClass = "";
	export let disabled = false;
	export { customClass as class };
	export let input: HTMLInputElement | null = null;

	const dispatch = createEventDispatcher<{
		input: number;
		change: number;
	}>();

	let holdTimeout: number | undefined = undefined;
	const holdIntervalSpeed = 250;
	const minimalIntervalSpeed = 50;
	const intervalSpeedDrop = 15;

	value = String(valueAsNumber);

	$: valueIsEmpty = value === "" || isNaN(Number(value));

	function onValueAsNumberChanged(valueAsNumber: number): void {
		value = String(valueAsNumber);
		valueIsEmpty = value === "" || isNaN(valueAsNumber);
	}
	$: onValueAsNumberChanged(valueAsNumber);
	$: valueAsNumber = valueIsEmpty ? 0 : Number(value);

	function onChange(): void {
		value = value === "" ? 0 : value;
		setTimeout(() => {
			dispatch("change", valueAsNumber);
		}, 0);
	}

	function stopHold(): void {
		clearTimeout(holdTimeout);
		holdTimeout = undefined;
	}

	function decrement(): void {
		if (valueIsEmpty) {
			value = 0;
		} else if (Number(value) > min) {
			value = Number(value) - 1;
		}
		onChange();
	}

	function startDecrementing(speed = holdIntervalSpeed): void {
		stopHold();
		holdTimeout = window.setTimeout(() => {
			decrement();
			startDecrementing(Math.max(minimalIntervalSpeed, speed - intervalSpeedDrop));
		}, speed);
	}

	function increment(): void {
		if (valueIsEmpty) {
			value = 0;
		} else if (Number(value) < max) {
			value = Number(value) + 1;
		}
		onChange();
	}

	function startIncrementing(speed = holdIntervalSpeed): void {
		stopHold();
		holdTimeout = window.setTimeout(() => {
			increment();
			startIncrementing(Math.max(minimalIntervalSpeed, speed - intervalSpeedDrop));
		}, speed);
	}

	$: {
		dispatch("input", valueAsNumber);
	}

	function filterNonNumeric(event: Event): void {
		const input = event.target as HTMLInputElement;
		input.value = input.value.replace(/[^0-9]/gu, "");
	}

	$: {
		if (input) {
			const valueIsOutOfBounds =
				!valueIsEmpty && ((min !== -Infinity && Number(value) < min) || (max !== Infinity && Number(value) > max));

			if (valueIsEmpty && required) {
				input.setCustomValidity("Toto pole je povinné.");
			} else if (valueIsOutOfBounds) {
				if (min === -Infinity && max !== Infinity) {
					input.setCustomValidity(`Hodnota musí být maximálně ${max}.`);
				} else if (min !== -Infinity && max === Infinity) {
					input.setCustomValidity(`Hodnota musí být minimálně ${min}.`);
				} else if (min !== -Infinity && max !== Infinity) {
					input.setCustomValidity(`Hodnota musí být v rozmezí ${min} až ${max}.`);
				}
			} else {
				input.setCustomValidity("");
			}
		}
	}

	// eslint-disable-next-line no-warning-comments
	// FIXME remove this after upgrade to Svelte 5.
	/* eslint-disable @typescript-eslint/explicit-function-return-type */
</script>

<svelte:window on:mouseup={stopHold} />

<div class="{customClass} rounded-2.5xl border-1 border-grey-200 flex h-10 items-center gap-2 p-2">
	<Button
		type="button"
		variant="number"
		on:click={decrement}
		on:mousedown={() => {
			startDecrementing();
		}}
		on:blur={stopHold}
		{disabled}
	>
		-
	</Button>
	<input
		bind:this={input}
		class="{inputClass} w-full flex-1 text-center font-semibold"
		class:text-secondary-300={disabled}
		type="text"
		{id}
		name={id}
		{disabled}
		bind:value
		on:input={filterNonNumeric}
		on:change={onChange}
		maxlength={max !== Infinity ? String(max).length : undefined}
		on:keydown
	/>
	<Button
		type="button"
		variant="number"
		on:click={increment}
		on:mousedown={() => {
			startIncrementing();
		}}
		on:blur={stopHold}
		{disabled}
	>
		+
	</Button>
</div>
